import React from "react";
import Image from "next/legacy/image";
import { imageLoader } from "@/utils/image";

// As Seen On
// 1. CBC News
// image: https://cdn.purpledoorfinders.com/purpledoorfinders/cbc-news.png
// link: https://www.cbsnews.com/sacramento/news/senior-living-database-created-by-folsom-woman/

// 2. Sacramento Business Journal
// image: https://cdn.purpledoorfinders.com/purpledoorfinders/sacramento-business-jornal.png
// link: https://www.bizjournals.com/sacramento/news/2024/02/22/christina-bremner-purple-door-finders.html

// 3. Style Magazine
// image: https://cdn.purpledoorfinders.com/purpledoorfinders/style-magazine.png
// link: https://www.stylemg.com/2024/12/30/517921/q-a-with-local-companies-purple-door-finders

const AsSeenOn = () => {
    return (
        <div className="bg-gray-200 px-4 pt-2 rounded-lg">
            <div className="ontainer mx-auto px-8 sm:px-16 pt-8 pb-16">
                <h3 className="font-headline font-bold text-primary-500 text-3xl text-center">
                    As Seen On
                </h3>

                <div className="mt-8 flex flex-col md:flex-row justify-center items-center gap-x-16 gap-y-8">
                    <a
                        href="https://www.cbsnews.com/sacramento/news/senior-living-database-created-by-folsom-woman/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative w-32 h-32"
                    >
                        <Image
                            loader={imageLoader}
                            src="https://cdn.purpledoorfinders.com/purpledoorfinders/cbc-news.sm.jpg"
                            alt="CBC News"
                            layout="fill"
                            objectFit="contain"
                            objectPosition="center"
                            priority
                            unoptimized={false}
                            className=""
                        />
                    </a>

                    <a
                        href="https://www.bizjournals.com/sacramento/news/2024/02/22/christina-bremner-purple-door-finders.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative w-72 h-32"
                    >
                        <Image
                            loader={imageLoader}
                            src="https://cdn.purpledoorfinders.com/purpledoorfinders/sacramento-business-jornal.sm.jpg"
                            alt="Sacramento Business Journal"
                            layout="fill"
                            objectFit="scale-down"
                            objectPosition="center"
                            priority
                            unoptimized={false}
                            className=""
                        />
                    </a>

                    <a
                        href="https://www.stylemg.com/2024/12/30/517921/q-a-with-local-companies-purple-door-finders"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative w-72 h-32"
                    >
                        <img
                            src="https://cdn.purpledoorfinders.com/purpledoorfinders/style-magazine.png"
                            alt="Style Magazine"
                            className="w-full h-full object-scale-down"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AsSeenOn;
