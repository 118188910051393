import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

import HeaderAndSearch from "./HeaderAndSearch";
import HomeCarousel from "./HomeCarousel";
import HowToUse from "./HowToUse";
import HomeTheEasyWay from "./HomeTheEasyWay";
import HomeBetterWay from "./HomeBetterWay";
import HomeOurValues from "./HomeOurValues";
import HomeFacilities from "./HomeFacilities";
import HomeGuidebook from "./HomeGuidebook";
import HomeCreateAccount from "./HomeCreateAccount";
import SeoLinks from "./SeoLinks";
import AsSeenOn from "./AsSeenOn";

import HomeFooter from "./HomeFooter";

import PurpleDoorLogoRect from "../Logo/PurpleDoorLogoRect";

const Home = ({ redirectToHome }) => {
    const router = useRouter();

    useEffect(() => {
        if (redirectToHome) {
            router.replace("/");
        }
    }, []);

    return (
        <div>
            <HeaderAndSearch />

            <div className="bg-white">
                <div className="container mx-auto px-4 sm:px-16">
                    <div className="hidden sm:block">
                        <HomeCarousel />
                    </div>
                </div>

                <div className="sm:mt-8 container mx-auto px-0 sm:px-16">
                    {/*<HowToUse />*/}
                    <HomeTheEasyWay />
                    <HomeBetterWay />
                </div>

                <HomeOurValues />

                <AsSeenOn />
            </div>

            <SeoLinks />
            <HomeFooter />
        </div>
    );
};

export default Home;
