import React, { useState } from "react";
import { useRouter } from "next/router";
import Link from "@/utils/link";
import Image from "next/legacy/image";

import HomeHeader, { links } from "./HomeHeader";
import HomeSearch from "./HomeSearch";
//import { useWindowSize } from "@/utils/hooks";
import { imageLoader } from "@/utils/image";

export const LinkItem = ({ link, label, path }) => {
    return (
        <div
            className={
                "px-2 py-1 text-primary-500 uppercase hover:bg-primary-100 hover:rounded" +
                (path === link ? " border-b-2 border-primary-500" : "")
            }
        >
            <Link href={link}>{label}</Link>
        </div>
    );
};

const HeaderAndSearch = () => {
    const router = useRouter();
    //const { width, height } = useWindowSize();

    const path = router.pathname;

    return (
        <div className="w-full">
            <HomeHeader fixed={false} />

            <div className="hidden sm:block sm:relative w-full h-[280px] bg-[#CECDD7]">
                <div className="absolute top-0 right-0 w-[550px] h-[280px]">
                    <Image
                        loader={imageLoader}
                        src="https://cdn.purpledoorfinders.com/purpledoorfinders/new-main-image"
                        alt="purple door senior living"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="0px -120px"
                        priority
                        unoptimized={false}
                        className="opacity-80 transform scale-100"
                    />
                </div>

                <div className="relative container mx-auto mb-8 pt-6 px-4 sm:px-16">
                    <h2 className="mt-4 text-primary-500 text-4xl font-bold tracking-normal font-headline">
                        Start your search for senior living & care
                    </h2>
                </div>

                <div className="sm:container sm:mx-auto px-4 sm:px-16">
                    <HomeSearch enableLocation={true} higherInput={true} />
                </div>
            </div>

            <div className="relative block w-full h-[300px] sm:hidden bg-[#CECDD7]">
                <div className="">
                    <Image
                        loader={imageLoader}
                        src="https://cdn.purpledoorfinders.com/purpledoorfinders/new-main-image"
                        alt="purple door senior living"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                        priority
                        unoptimized={false}
                        className="opacity-80"
                    />
                </div>

                <div className="relative px-4 pt-8">
                    <h2 className="mt-4 text-primary-500 text-3xl font-bold">
                        Start your search for senior living & care
                    </h2>
                </div>

                <div className="sm:container sm:mx-auto mt-8 px-4 sm:px-16">
                    <HomeSearch enableLocation={true} higherInput={true} />
                </div>
            </div>
        </div>
    );
};

export default HeaderAndSearch;
